import { navigate, PageProps } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'
import RegistrationReview from '../../../components/register/review/review'
import { CartType } from '../../../services/api/endpoints/carts'

const RegisterPlayerReviewPage = (props: PageProps) => {
  const cartType: CartType = 'referee'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  const onSuccess = () => {
    navigate(`/register/${cartType}/success`)
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <RegistrationReview onSuccess={onSuccess} cartType={cartType} />
    </>
  )
}

export default RegisterPlayerReviewPage
